<template lang="pug">
  div.home
    section.sec.birthday_wrap(v-if="Object.keys(birthdayMembers).length")
      h2 &#x1f381; 本日の主役
      p お誕生日おめでとうございます&#x1f382;
      ul
        li(v-for="user in birthdayMembers" :key="user.id")
          router-link(:to="{name: 'member', params: {id: user.id}}")
            span.department {{ user.departmentId|convertMasterName('departments', master)|abridgementDeparment }}
            span.underline {{ user.name }}
            span さん &#x1f38a;
    section.sec.news_wrap(v-if="Object.keys(newMembers).length")
      h2
        IconBase(width="21" height="23" icon-name="bell")
          IconBell
        span お知らせ
      dl(v-for="(users, hireDate) in newMembers" :key="hireDate")
        dt
          IconBase(width="14" height="16" icon-name="calendar-alt")
            IconCalendarAlt
          span {{ hireDate }}
        dd
          ul
            li(v-for="user in users" :key="user.id")
              router-link(:to="{name: 'member', params: {id: user.id}}")
                span.department {{ user.departmentId|convertMasterName('departments', master)|abridgementDeparment }}
                div
                  span.underline {{ user.name }}
                  span さんが入社{{ isFuture(hireDate) }}&#x1f389;
    div.fl_wrap
      section.sec.company_wrap
        h2
          IconBase(width="22" height="25" icon-name="building")
            IconBuilding
          span 社員内訳
        table
          tbody
            tr(v-for="departmentGroup in departmentsUsers" :key="Object.keys(departmentGroup).shift()")
              th {{ Object.keys(departmentGroup).shift() | convertMasterName('departments', master) }}
              td.num {{ departmentGroup[Object.keys(departmentGroup).shift()] }}
                small 名
              td.link
                router-link(:to="{name: 'members', hash: '#department_' + Object.keys(departmentGroup).shift()}")
                  IconBase(width="23" height="18" icon-name="eye")
                    IconEye
            tr.total
              th 合計
              td.num(colspan="2") {{ Object.keys(users).length }}
                small 名
      section.sec.document_wrap
        h2
          a(href="https://drive.google.com/drive/folders/12RXUUYp7niWBQYUsxg0wYxNRfRUYtgM0?usp=sharing" target="_blank")
            img(src="@/assets/images/gdrive.png" alt="Google Drive" width="30" height="30" loading="lazy")
            span relationドキュメント
            IconBase.ex(width="12" height="12" icon-name="ex-link")
              IconExLink
        ul.document_list
          li
            a(href="https://drive.google.com/drive/folders/1TndafsQI0jfsTCLJ_cXkr1mCTsckcbLV?usp=sharing" target="_blank")
              IconBase(width="45" height="30" icon-name="folder")
                IconFolder
              span 組織・体制図
          li
            a(href="https://drive.google.com/drive/folders/1u9S283NkheYTOID8ZZ_h3NBx3PC4YFjE?usp=sharing" target="_blank")
              IconBase(width="45" height="30" icon-name="folder")
                IconFolder
              span 全体会議
          li
            a(href="https://drive.google.com/drive/folders/1wCYsvsPIs5iyBvhkdQ6sYxrzCk-6bB3Z" target="_blank")
              IconBase(width="45" height="30" icon-name="folder")
                IconFolder
              span 福利厚生
          li
            a(href="https://drive.google.com/drive/folders/1zx_KAevC-RhPLWVtZQNwWG5zRen6C0HS?usp=sharing" target="_blank")
              IconBase(width="45" height="30" icon-name="folder")
                IconFolder
              span 各種申請書類
          li
            a(href="https://drive.google.com/drive/folders/1A5nY5oYU688VMESE6Y6gtWKcAjMo6qzb?usp=sharing" target="_blank")
              IconBase(width="45" height="30" icon-name="folder")
                IconFolder
              span 運用ルール・手順
          li
            a(href="https://drive.google.com/drive/folders/1sB02C4nDhNrHlXR-QWVU6vgKqvvkJrOD?usp=sharing" target="_blank")
              IconBase(width="45" height="30" icon-name="folder")
                IconFolder
              span 就業規則関連
      section.sec.hr_wrap
        h2
          IconBase(width="21" height="23" icon-name="qa")
            IconAccount
          span HR相談窓口
        div.hr_contents
          div.item.left
            p.description 会社のこと、上司/部下のこと、人間関係のこと、その他もろもろ、何か相談したいことがありましたら、「相談窓口」よりいつでもご相談ください。
            ul.attentions
              li この窓口は匿名ではありません。
              li HR以外に情報が漏れることは絶対にございません。
          div.item
            a.hr_link(href="https://docs.google.com/forms/d/e/1FAIpQLSdOBfAmwyW4jsGz8i57HB0hIRg-_rxXc2AltfU_zR6tte9Opg/viewform?usp=sf_link" target="_blank") HR相談窓口へ
      section.sec.hr_wrap
        h2
          IconBase(width="21" height="23" icon-name="qa")
            IconAccount
          span ハラスメント相談窓口
        div.hr_contents
          div.item.left
            p.description 相談者、および相談内容は守秘義務により守られますので安心してご相談ください。また、相談したことにより相談者があらゆる不利益を被ることはございません。
            ul.attentions
              li この窓口は匿名ではありません。
              li HR以外に情報が漏れることは絶対にございません。
          div.item
            p
              a.hr_link(href="https://docs.google.com/forms/d/e/1FAIpQLSeR1yC7dJvgRLCc657VKr4K7KxWKX5nlSMfFOP4fSNS7sXTkQ/viewform" target="_blank") ハラスメント相談窓口へ
            p.txt_link_wrap
              a.txt_link(href="https://drive.google.com/file/d/1F3xCJUphHRRP2D-Q_0TB_8G95VN8cMoj/view?usp=sharing" target="_blank") ハラスメントに対する社長の決意表明

      section.sec.qa_wrap
        h2
          IconBase(width="21" height="23" icon-name="qa")
            IconQA
          span よくある質問

        ul.qa_list
          li(v-for="(qa, index) in qa" :key="index")
            dl(:class="{ isOpen: show == index }")
              dt(@click="toggleAccordion(index)")
                em Q.
                p(v-html="qa.question")
              dd(v-html="qa.answer")
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import IconBase from '@/components/IconBase.vue'
import IconBell from '@/components/icons/IconBell.vue'
import IconCalendarAlt from '@/components/icons/IconCalendarAlt.vue'
import IconBuilding from '@/components/icons/IconBuilding.vue'
import IconEye from '@/components/icons/IconEye.vue'
import IconExLink from '@/components/icons/IconExLink.vue'
import IconFolder from '@/components/icons/IconFolder.vue'
import IconQA from '@/components/icons/IconQA.vue'
import IconAccount from '@/components/icons/IconAccount.vue'

dayjs.locale('ja')
export default {
  name: 'HomeView',
  metaInfo: {
    title: 'ホーム画面',
  },
  data() {
    return {
      show: 'none',
      qa: [
        {
          question: '経費精算書や定期代申請書の提出について',
          answer:
            '<a href="https://docs.google.com/spreadsheets/d/1qRpZIAB6jAelYEuqAXiL_YOREdKODLMw/edit?usp=drive_link&ouid=108558956458425060179&rtpof=true&sd=true" target="_blank">経費精算書</a>や<a href="https://docs.google.com/spreadsheets/d/15YJU5EYo9mbDr1xLqWG5m0fUMtYwcvzo/edit?usp=drive_link&ouid=108558956458425060179&rtpof=true&sd=true" target="_blank">定期代申請書</a>の提出は、翌月2営業日以内に<a href="mailto:keiri@relation2012.com">keiri@relation2012.com</a>宛にメール送信してください。',
        },
        {
          question: '資格取得による祝金について',
          answer:
            '・<a href="https://drive.google.com/file/d/1F-DdVYRDaUoGUgyAMk4PdQzIpeM9JS8M/view?usp=sharing" target="_blank">こちらの資料</a>に記載されている資格の場合、資格取得がわかる証明書を添付の上、<a href="mailto:keiri@relation2012.com">keiri@relation2012.com</a>宛にメール送信してください。<br><br>・上記以外の資格の場合、資格によっては取得後に受験料が補助されますので、取得する前に上長へ確認してください。受験料の補助が認めらた場合、資格取得がわかる証明書と受験料の領収書を添付の上、<a href="mailto:keiri@relation2012.com">keiri@relation2012.com</a>宛にメール送信してください。',
        },
        {
          question: '引越しの際に必要な手続きについて',
          answer:
            '<a href="https://docs.google.com/document/d/1VCkmc0mKfSqvGF7p9gfv-iVqRZz-_CQq/edit?usp=drive_link&ouid=108558956458425060179&rtpof=true&sd=true" target="_blank">住所変更届</a>を添付の上、<a href="mailto:keiri@relation2012.com">keiri@relation2012.com</a>宛にメール送信してください。<br>また、<a href="https://docs.google.com/forms/d/e/1FAIpQLSewFTD39SC8qbC74VMKdbU5hcqLtdfel9_E_gApYI4RYS4xpw/viewform?usp=sf_link" target="_blank">こちらのフォーム</a>より、緊急連絡先の変更をお願いします。',
        },
        {
          question: '子の看護休暇取得について',
          answer:
            '<a href="https://docs.google.com/document/d/1giUd4Sj6OtXHBbs5F-cHAFBoQQq8mkv95ic_SrR5JfU/edit?usp=sharing" target="_blank">こちらの資料</a>をご確認ください。',
        },
        {
          question: '結婚、出産、傷病など、慶弔災害届出書について',
          answer:
            '<a href="https://docs.google.com/spreadsheets/d/1vVRrHH9YHlWxyCi_iWO1IdjUD6eSOrrs/edit#gid=442013831" target="_blank">慶弔災害届出書</a>を添付の上、<a href="mailto:keiri@relation2012.com">keiri@relation2012.com</a>宛にメール送信してください。<br>住民票などは原本ではなく、PDFもしくは写真を添付してください。',
        },
        {
          question: '前職（前年度）の源泉徴収票の提出について',
          answer:
            '源泉徴収票は、PDFを添付の上、<a href="mailto:keiri@relation2012.com">keiri@relation2012.com</a>宛にメール送信してください。',
        },
        {
          question: '子どもの保険証発行について',
          answer:
            '下記いずれかをPDFで添付の上、<a href="mailto:keiri@relation2012.com">keiri@relation2012.com</a>宛にメール送信してください。<br>・お子様のマイナンバー通知書<br>・お子様のマイナンバーカード<br>・お子様のマイナンバー入りの住民票（出産直後など上記2つがない場合）',
        },
        {
          question:
            '勤怠証明書、源泉徴収票、保険証などの問い合わせ窓口について',
          answer:
            '<a href="mailto:keiri@relation2012.com">keiri@relation2012.com</a>宛にメールにてお問い合わせください。',
        },
        {
          question: '目安箱について',
          answer:
            '匿名で会社に意見や提案ができる目安箱は<a href="https://drive.google.com/file/d/1saAYxHp2f531XcyvSlYTQqEDuNZjnbn-/view?usp=sharing" target="_blank">こちら</a>。',
        },
      ],
    }
  },
  components: {
    IconBase,
    IconBell,
    IconCalendarAlt,
    IconBuilding,
    IconEye,
    IconExLink,
    IconFolder,
    IconQA,
    IconAccount,
  },
  computed: {
    ...mapState('usersModule', ['users']),
    ...mapState('masterModule', ['master']),
    newMembers() {
      const newMembers = {}
      Object.keys(this.users).forEach((id) => {
        const account = this.users[id]
        const hireDate = account.hireDate
        if (typeof hireDate != 'undefined' && hireDate != null) {
          const hireDateObj = dayjs(new Date(hireDate.seconds * 1000))
          if (hireDateObj.isAfter(dayjs(new Date()).subtract(1, 'months'))) {
            if (!(hireDateObj.format('YYYY/M/D') in newMembers)) {
              newMembers[hireDateObj.format('YYYY/M/D')] = []
            }
            newMembers[hireDateObj.format('YYYY/M/D')].push(account)
          }
        }
      })
      Object.keys(newMembers).forEach((date) => {
        newMembers[date].sort(
          (a, b) =>
            this.master.departments[a.departmentId].sortId -
            this.master.departments[b.departmentId].sortId,
        )
      })
      const sortData = {}
      Object.keys(newMembers)
        .sort(function (a, b) {
          return a > b ? -1 : 1
        })
        .forEach((date) => {
          sortData[date] = newMembers[date]
        })
      return sortData
    },
    birthdayMembers() {
      const birthdayMembers = []
      Object.keys(this.users).forEach((id) => {
        const account = this.users[id]
        const birthday = account.birthday
        if (typeof birthday != 'undefined' && birthday != null) {
          const birthdayObj = dayjs(new Date(birthday.seconds * 1000))
          const todayObj = dayjs()
          if (
            birthdayObj.month() == todayObj.month() &&
            birthdayObj.date() == todayObj.date()
          ) {
            birthdayMembers.push(account)
          }
        }
      })
      return birthdayMembers
    },
    departmentsUsers() {
      const array = {}
      Object.keys(this.users).forEach((id) => {
        const account = this.users[id]
        if (!(account.departmentId in array)) {
          array[account.departmentId] = 0
        }
        array[account.departmentId]++
      })
      let sortDepartment = Object.keys(array).sort(
        (a, b) =>
          this.master.departments[a].sortId - this.master.departments[b].sortId,
      )
      let resutl = []
      sortDepartment.forEach((departmentId) => {
        resutl.push({ [departmentId]: array[departmentId] })
      })
      return resutl
    },
  },
  methods: {
    isFuture(hireDate) {
      if (dayjs(new Date(hireDate)).isAfter(dayjs(new Date()))) {
        return '予定です'
      } else {
        return 'しました'
      }
    },
    toggleAccordion: function (id) {
      this.show = this.show == id ? 'none' : id
    },
  },
}
</script>

<style lang="scss" scoped>
.sec {
  max-width: 1020px;
  padding: 10px 15px;
  @include tab {
    padding: 10px 20px;
  }
  margin: 20px auto 15px;
  h2 {
    margin: 15px 0 10px;
  }
  h2,
  h2 a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 25px;
    line-height: 1;
    span {
      padding-left: 5px;
      font-size: 18px;
    }
  }
  a {
    color: #333;
    text-decoration: none;
  }
  &.birthday_wrap {
    text-align: left;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    border-radius: 20px;
    margin: 25px auto 35px;
    width: calc(100% - 30px);
    @include tab {
      width: calc(100% - 40px);
    }
    max-width: 800px;
    padding: 0 0 20px;
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 15px;
      font-size: 18px;
      background-color: #f1976a;
      background-image: linear-gradient(315deg, #f99969 0%, #ffb087 74%);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 10px 0;
      color: #fff;
      span {
        color: #fff;
      }
    }
    ul {
      margin: 10px 0 5px;
      padding: 0 20px;
      text-align: center;
      li {
        padding: 10px 0 5px;
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          .department {
            padding: 5px 8px;
            margin: 0 3px 0 0;
            font-size: 11px;
            border-radius: 40px;
            background: #8e9a98;
            color: #fff;
            text-shadow: 1px 1px 1px #afafaf3b;
          }
          .underline {
            text-decoration: underline;
          }
        }
      }
    }
    p {
      font-size: 18px;
      @include iphone5 {
        font-size: 16px;
      }
      text-align: center;
      font-weight: bold;
      padding: 0 20px;
    }
  }
  &.news_wrap {
    text-align: left;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    border-radius: 20px;
    margin: 25px auto 10px;
    width: calc(100% - 30px);
    @include tab {
      width: calc(100% - 40px);
    }
    max-width: 800px;
    padding: 0 0 20px;
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 15px;
      font-size: 23px;
      background-color: #88cabe;
      background-image: linear-gradient(315deg, #6bc5b3 0%, #88cabe 74%);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 10px 0;
      color: #fff;
      span {
        color: #fff;
      }
    }
    dl {
      padding: 0 20px 15px;
      @include iphone5 {
        padding: 0 15px 15px;
      }
      &:last-child {
        padding-bottom: 0;
      }
      dt {
        display: flex;
        align-items: center;
        letter-spacing: 2px;
        font-size: 16px;
        margin: 0 0 13px;
        color: #666;
        span {
          margin-left: 5px;
          font-size: 14px;
        }
      }
      dd {
        padding: 0 3px;
        font-size: 14px;
        ul li {
          line-height: 1.6;
          margin: 0 0 8px;
          &:last-child {
            margin: 0;
          }
          a {
            display: flex;
            align-items: center;
            span.department {
              padding: 2px 8px;
              margin: 0 3px 0 0;
              font-size: 11px;
              border-radius: 40px;
              background: #8e9a98;
              color: #fff;
              text-shadow: 1px 1px 1px #afafaf3b;
            }
            span.underline {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  &.company_wrap {
    h2 {
      color: #87a2cb;
      span {
        color: #333;
      }
    }
    table {
      width: calc(100% - 10px);
      margin: 20px auto 0;
      line-height: 1.5;
      font-size: 14px;
      th {
        text-align: left;
        padding: 5px 10px;
        border-bottom: 1px solid #d9d9d9;
        vertical-align: middle;
      }
      td {
        padding: 7px 10px;
        text-align: left;
        border-bottom: 1px solid #d1d1d1;
        vertical-align: middle;
        &.num {
          white-space: nowrap;
          small {
            padding-left: 2px;
            font-size: 12px;
          }
        }
        &.link {
          text-align: center;
          font-size: 20px;
          a {
            color: #87a2cbbf;
            display: flex;
            justify-content: center;
          }
        }
      }
      .total {
        th,
        td {
          border-bottom: none;
        }
      }
    }
  }
  &.document_wrap {
    padding: 10px;
    h2 a {
      position: relative;
      padding-right: 12px;
      .ex {
        position: absolute;
        font-size: 12px;
        right: 0;
        top: 3px;
      }
    }
    .document_list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        width: calc(50% - 16px);
        margin: 15px 8px 0;
        a {
          display: block;
          padding: 10px 15px;
          font-size: 40px;
          border-radius: 10px;
          border: 2px solid rgb(192, 192, 192);
          color: #e4be8e;
          span {
            display: block;
            margin: 5px 0 0;
            font-size: 15px;
            color: #444;
          }
        }
      }
    }
  }
  &.hr_wrap {
    h2 {
      color: #2ca28c;
      span {
        color: #333;
      }
    }
    .hr_contents {
      width: 100%;
      @include tab {
        width: 95%;
        margin: 0 auto;
      }
      @include pc {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .item {
        margin: 30px 0 0;
        .description {
          line-height: 1.6;
          text-align: left;
        }
        .attentions {
          margin: 10px 0 0;
          li {
            font-size: 14px;
            text-align: left;
            color: #e28181;
            margin: 0 0 8px;
            &:last-child {
              margin: 0;
            }
            strong {
              font-weight: bold;
            }
            &::before {
              content: '※ ';
            }
          }
        }
        .hr_link {
          position: relative;
          display: inline-block;
          padding: 15px 45px 15px 25px;
          font-size: 18px;
          border-radius: 5px;
          cursor: pointer;
          background-color: #43c5ad;
          color: #fff;
          background-image: linear-gradient(315deg, #43c5ad 0%, #1e927c 74%);
          margin: 10px auto 0;
          line-height: 1.5;
          @include pc {
            white-space: nowrap;
            margin-left: 50px;
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 15px;
            content: '';
            width: 10px;
            height: 10px;
            border-top: 3px solid #fff;
            border-right: 3px solid #fff;
            transform: translateY(-50%) rotate(45deg);
          }
        }
        .txt_link_wrap {
          margin: 5px auto 0;
          text-align: center;
          .txt_link {
            text-align: center;
            @include pc {
              white-space: nowrap;
              margin-left: 50px;
            }
            text-decoration: underline;
            font-size: 0.8em;
          }
        }
      }
    }
  }
  &.qa_wrap {
    h2 {
      color: #f98952;
      span {
        color: #333;
      }
    }
    .qa_list {
      margin: 20px 0 0;
      li {
        padding: 20px 15px;
        border-bottom: 1px solid #d1d1d1;
        dl {
          text-align: left;
          cursor: pointer;
          dt {
            position: relative;
            font-weight: bold;
            display: flex;
            align-items: center;
            padding-right: 30px;
            em {
              font-size: 30px;
              margin: 0 10px 0 0;
              color: #ffbcbc;
            }
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 45%;
              right: 0;
              content: '';
              width: 10px;
              height: 10px;
              border-top: 3px solid #444;
              border-right: 3px solid #444;
              transform: translateY(-50%) rotate(135deg);
            }
          }
          dd {
            display: none;
            margin: 20px 0 0;
            line-height: 1.7;
            padding: 0 15px;
            transition: 0.5s;
            height: 0;
            transition: height 0.3s linear;
          }
          &.isOpen {
            dt::after {
              transform: translateY(-50%) rotate(-45deg);
            }
            dd {
              display: block;
              padding: 20px 15px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
.fl_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1020px;
  margin: 0 auto;
  @include pc {
    margin: 30px auto 0;
  }
  .sec {
    width: 100%;
    @include pc {
      width: calc(50% - 50px);
    }
    &.hr_wrap,
    &.qa_wrap {
      @include pc {
        width: 100%;
      }
    }
  }
}
</style>
<style>
.qa_wrap a {
  color: #333;
}
</style>
